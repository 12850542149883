import axios from "@/utils/axios";

// GRUPOS DE OPCIONAIS
const getGrupos = async (cod_opcional_grupo = "", pagination = null) => {
  let result = { data: [], pagination: null };
  await axios
    .get("/produtosOpcionais/grupos", { params: { cod_opcional_grupo, pagination } })
    .then((response) => {
      // A API retorna diretamente um array de grupos, não um objeto com propriedade data
      result.data = response.data;
      
      // Se houver informações de paginação nos headers, por exemplo
      if (response.headers && response.headers['x-pagination']) {
        try {
          result.pagination = JSON.parse(response.headers['x-pagination']);
        } catch (e) {
          console.warn('Erro ao processar informações de paginação:', e);
        }
      }
    })
    .catch((error) => {
      throw new Error(error.response?.data?.error?.message || error.message);
    });

  return result;
};

const storeGrupo = async (data) => {
  let result;

  await axios
    .post("/produtosOpcionais/grupos", data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const updateGrupo = async (cod_opcional_grupo, data) => {
  let result;

  await axios
    .put(`/produtosOpcionais/grupos/${cod_opcional_grupo}`, data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const deleteGrupo = async (cod_opcional_grupo) => {
  let result;

  await axios
    .delete(`/produtosOpcionais/grupos/${cod_opcional_grupo}`)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

// OPCIONAIS
const getOpcionais = async (cod_opcional = "", pagination = null, cod_opcional_grupo = null) => {
  let result = { data: [], pagination: null };
  await axios
    .get("/produtosOpcionais/opcionais", { params: { cod_opcional, pagination, cod_opcional_grupo } })
    .then((response) => {
      // A API retorna diretamente um array de opcionais, não um objeto com propriedade data
      result.data = response.data;
      
      // Se houver informações de paginação nos headers, por exemplo
      if (response.headers && response.headers['x-pagination']) {
        try {
          result.pagination = JSON.parse(response.headers['x-pagination']);
        } catch (e) {
          console.warn('Erro ao processar informações de paginação:', e);
        }
      }
    })
    .catch((error) => {
      throw new Error(error.response?.data?.error?.message || error.message);
    });

  return result;
};

const storeOpcional = async (data) => {
  let result;

  await axios
    .post("/produtosOpcionais/opcionais", data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const updateOpcional = async (cod_opcional, data) => {
  let result;

  await axios
    .put(`/produtosOpcionais/opcionais/${cod_opcional}`, data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const deleteOpcional = async (cod_opcional) => {
  let result;

  await axios
    .delete(`/produtosOpcionais/opcionais/${cod_opcional}`)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

// ITENS DE OPCIONAIS
const getItens = async (id = "", opcional_id = null, pagination = null) => {
  let result = { data: [], pagination: null };
  await axios
    .get("/produtosOpcionais/itens", { params: { id, opcional_id, pagination } })
    .then((response) => {
      // A API retorna diretamente um array de itens, não um objeto com propriedade data
      result.data = response.data;
      
      // Se houver informações de paginação nos headers, por exemplo
      if (response.headers && response.headers['x-pagination']) {
        try {
          result.pagination = JSON.parse(response.headers['x-pagination']);
        } catch (e) {
          console.warn('Erro ao processar informações de paginação:', e);
        }
      }
    })
    .catch((error) => {
      throw new Error(error.response?.data?.error?.message || error.message);
    });

  return result;
};

const storeItem = async (data) => {
  let result;

  await axios
    .post("/produtosOpcionais/itens", data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const updateItem = async (id, data) => {
  let result;

  await axios
    .put(`/produtosOpcionais/itens/${id}`, data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const deleteItem = async (id) => {
  let result;

  await axios
    .delete(`/produtosOpcionais/itens/${id}`)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

// RELAÇÕES PRODUTO-OPCIONAL
const getProdutoOpcionais = async (cod_produto = "", pagination = null) => {
  let result = { data: [], pagination: null };
  await axios
    .get("/produtosOpcionais/produto", { params: { cod_produto, pagination } })
    .then((response) => {
      // A API retorna diretamente um array de relações, não um objeto com propriedade data
      result.data = response.data;
      
      // Se houver informações de paginação nos headers, por exemplo
      if (response.headers && response.headers['x-pagination']) {
        try {
          result.pagination = JSON.parse(response.headers['x-pagination']);
        } catch (e) {
          console.warn('Erro ao processar informações de paginação:', e);
        }
      }
    })
    .catch((error) => {
      throw new Error(error.response?.data?.error?.message || error.message);
    });

  return result;
};

const storeProdutoOpcional = async (data) => {
  let result;

  await axios
    .post("/produtosOpcionais/produto", data)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

const deleteProdutoOpcional = async (cod_produto_opcional_rel) => {
  let result;

  await axios
    .delete(`/produtosOpcionais/produto/${cod_produto_opcional_rel}`)
    .then((res) => (result = res))
    .catch((error) => { throw new Error(error.response.data.error) });

  return result;
};

export default {
  // Grupos
  getGrupos,
  storeGrupo,
  updateGrupo,
  deleteGrupo,
  
  // Opcionais
  getOpcionais,
  storeOpcional,
  updateOpcional,
  deleteOpcional,
  
  // Itens
  getItens,
  storeItem,
  updateItem,
  deleteItem,
  
  // Relações
  getProdutoOpcionais,
  storeProdutoOpcional,
  deleteProdutoOpcional
}; 