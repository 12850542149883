<template>
  <div>
    <BarraCrud
      :onNovo="onNovo"
      :botaoNovo="canManage"
      :onFiltrar="filtrar"
    />
    <div>
      <div class="d-flex justify-content-between">
        <div
          class="text-left my-2 py-2 mx-2 px-2"
          style="max-width: 300px"
        >
          <b>Grupo de Opcionais</b>
          <b-select
            size="xl"
            :options="
              grupos.map((it) => ({ value: it.cod_opcional_grupo, text: it.nome }))
            "
            v-model="grupoSelecionado"
          >
            <template #first>
              <b-form-select-option :value="null">Todos os grupos</b-form-select-option>
            </template>
          </b-select>
        </div>
        <div
          v-if="loadingTable"
          class="text-right"
          style="place-content: flex-end"
        >
          <b-spinner
            class="mr-1"
            label="Spinning"
            type="grow"
            small
          ></b-spinner>
          Carregando...
        </div>
        <div>
          <b-button
            variant="outline-primary"
            class="mr-2 mt-4"
            @click="gerenciarGrupos"
          >
            <i class="fas fa-tags"></i> Gerenciar Grupos
          </b-button>
        </div>
      </div>
    </div>
    
    <b-skeleton-table v-if="loadingTable"></b-skeleton-table>
    
    <b-table
      v-if="!loadingTable"
      :items="opcionais"
      :fields="campos"
      responsive
      striped
      hover
      class="mb-0 mt-3"
    >
      <template #cell(tipo)="row">
        <span v-if="row.item.selecao_multipla" class="badge badge-info">Múltipla Seleção</span>
        <span v-else class="badge badge-secondary">Seleção Única</span>
      </template>
      
      <template #cell(obrigatorio)="row">
        <span v-if="row.item.obrigatorio" class="badge badge-danger">Sim</span>
        <span v-else class="badge badge-success">Não</span>
      </template>
      
      <template #cell(acoes)="row">
        <b-button
          size="sm"
          variant="info"
          class="mr-1"
          @click="gerenciarItens(row.item)"
          title="Gerenciar Itens do Opcional"
        >
          <i class="fas fa-list"></i>
        </b-button>
        <b-button
          size="sm"
          variant="warning"
          class="mr-1"
          @click="editar(row.item)"
          title="Editar Opcional"
        >
          <i class="fas fa-edit"></i>
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="excluir(row.item)"
          title="Excluir Opcional"
        >
          <i class="fas fa-trash"></i>
        </b-button>
      </template>
    </b-table>
    
    <b-pagination
      v-if="pagination.totalPages > 1"
      v-model="pagination.page"
      :total-rows="pagination.total"
      :per-page="pagination.limit"
      align="center"
      class="my-3"
    ></b-pagination>

    <!-- Modal de Edição -->
    <b-modal
      id="modal-opcionais"
      title="Opcional de Produto"
      size="lg"
      hide-footer
    >
      <ProdutosOpcionaisForm
        :opcional_edit="opcional_edit"
        :grupoInicial="grupoSelecionado"
        @salvou="carregar()"
        ref="opcionalForm"
      />
      <div class="mt-3 text-right">
        <b-button
          variant="primary"
          class="mr-2"
          @click="$refs.opcionalForm.salvar()"
        >
          <i class="fas fa-save"></i> Salvar
        </b-button>
        <b-button
          variant="danger"
          @click="$bvModal.hide('modal-opcionais')"
        >
          <i class="fas fa-times"></i> Cancelar
        </b-button>
      </div>
    </b-modal>

    <!-- Modal para Gerenciar Grupos -->
    <b-modal
      id="modal-grupos"
      size="lg"
      title="Gerenciar Grupos de Opcionais"
      hide-footer
    >
      <GruposDeOpcionais
        :botaoAdicionar="true"
        @adicionou="carregarGrupos"
        @salvou="carregarGrupos"
      />
    </b-modal>

    <!-- Modal para Gerenciar Itens -->
    <b-modal
      id="modal-itens"
      size="xl"
      title="Gerenciar Itens do Opcional"
      hide-footer
    >
      <ProdutosOpcionaisItens
        v-if="itemsOpcionalId"
        :opcional_id="itemsOpcionalId"
        :opcional_nome="itemsOpcionalNome"
      />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud";
import ProdutoOpcionalLib from "@/libs/ProdutoOpcionalLib.js";
import GruposDeOpcionais from "./GruposDeOpcionais.vue";
import ProdutosOpcionaisForm from "@/components/Produtos/ProdutosOpcionaisForm.vue";
import ProdutosOpcionaisItens from "@/components/Produtos/ProdutosOpcionaisItens.vue";

export default {
  components: {
    BarraCrud,
    GruposDeOpcionais,
    ProdutosOpcionaisForm,
    ProdutosOpcionaisItens
  },
  data() {
    return {
      filtro: "",
      opcionais: [],
      grupos: [],
      grupoSelecionado: null,
      loadingTable: false,
      opcional_edit: {},
      canManage: true,
      itemsOpcionalId: null,
      itemsOpcionalNome: "",
      campos: [
        { key: "cod_opcional", label: "ID", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "descricao", label: "Descrição" },
        { key: "grupo_nome", label: "Grupo", sortable: true },
        { key: "tipo", label: "Tipo de Seleção" },
        { key: "min_opcoes", label: "Mín. Opções", sortable: true },
        { key: "max_opcoes", label: "Máx. Opções", sortable: true },
        { key: "obrigatorio", label: "Obrigatório" },
        { key: "acoes", label: "Ações" },
      ],
      pagination: {
        limit: 10,
        page: 1,
        total: 0,
        totalPages: 0,
        filters: null,
      },
    };
  },
  watch: {
    "pagination.page": {
      handler() {
        if (this.loadingTable === false) {
          this.carregar();
        }
      },
      deep: true,
    },
    grupoSelecionado() {
      this.pagination.page = 1;
      this.carregar();
    },
  },
  mounted() {
    this.carregarGrupos();
    this.carregar();
  },
  methods: {
    async carregarGrupos() {
      try {
        const response = await ProdutoOpcionalLib.getGrupos();
        this.grupos = response.data || [];
        
        // Se houver grupos disponíveis e nenhum grupo estiver selecionado, selecionar o primeiro
        if (this.grupos.length > 0 && this.grupoSelecionado === null) {
          this.grupoSelecionado = this.grupos[0].cod_opcional_grupo;
        }
      } catch (error) {
        this.showToast("error", "Erro ao carregar grupos: " + error);
      }
    },
    
    async carregar() {
      this.loadingTable = true;
      try {
        const response = await ProdutoOpcionalLib.getOpcionais("", {
          page: this.pagination.page,
          limit: this.pagination.limit,
          filter: this.filtro,
        }, this.grupoSelecionado);

        this.opcionais = response.data || [];
        
        // Adicionar nome do grupo aos opcionais para exibição
        this.opcionais.forEach(opcional => {
          const grupo = this.grupos.find(g => g.cod_opcional_grupo === opcional.cod_opcional_grupo);
          opcional.grupo_nome = grupo ? grupo.nome : 'Sem grupo';
        });
        
        if (response.pagination) {
          this.pagination = response.pagination;
        }
      } catch (error) {
        this.showToast("error", "Erro ao carregar opcionais: " + error);
      } finally {
        this.loadingTable = false;
      }
    },
    
    filtrar(filtro) {
      this.filtro = filtro;
      this.pagination.page = 1;
      this.carregar();
    },
    
    limpar() {
      // Certifique-se de que um grupo válido está sempre selecionado
      let grupoDefault = this.grupoSelecionado;
      if (!grupoDefault && this.grupos.length > 0) {
        grupoDefault = this.grupos[0].cod_opcional_grupo;
      }
      
      this.opcional_edit = {
        nome: "",
        descricao: "",
        cod_opcional_grupo: grupoDefault,
        selecao_multipla: false,
        min_opcoes: 0,
        max_opcoes: 1,
        obrigatorio: false,
        ordem: 0
      };
    },
    
    onNovo() {
      // Se não houver grupos cadastrados, avisar o usuário e abrir a tela de gerenciamento de grupos
      if (this.grupos.length === 0) {
        this.showToast("warning", "É necessário cadastrar pelo menos um grupo de opcionais primeiro");
        this.gerenciarGrupos();
        return;
      }
      
      this.limpar();
      this.$bvModal.show("modal-opcionais");
    },
    
    editar(item) {
      this.opcional_edit = { ...item };
      this.$bvModal.show("modal-opcionais");
    },
    
    async excluir(item) {
      try {
        const result = await this.$swal({
          title: "Tem certeza?",
          text: `Deseja excluir o opcional "${item.nome}"?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir",
          cancelButtonText: "Cancelar",
        });
        
        if (result.isConfirmed) {
          await ProdutoOpcionalLib.deleteOpcional(item.cod_opcional);
          this.showToast("success", "Opcional excluído com sucesso!");
          this.carregar();
        }
      } catch (error) {
        this.showToast("error", "Erro ao excluir opcional: " + error);
      }
    },
    
    gerenciarGrupos() {
      this.$bvModal.show("modal-grupos");
    },
    
    gerenciarItens(item) {
      this.itemsOpcionalId = item.cod_opcional;
      this.itemsOpcionalNome = item.nome;
      this.$bvModal.show("modal-itens");
    },
    
    onSalvar() {
      this.carregar();
    },
    
    showToast(tipo, mensagem, tempo = 3000) {
      this.$swal({
        toast: true,
        position: "top-end",
        icon: tipo,
        title: mensagem,
        showConfirmButton: false,
        timer: tempo
      });
    },
  },
};
</script>

<style scoped>
.badge {
  font-size: 0.9em;
  padding: 5px 8px;
}
</style> 