<template>
  <div>
    <div v-if="!contentClass">
      <h3 class="pb-2 mt-4 px-2">
        <i :class="iconeCustomizado || 'fas fa-tags'"></i>
        {{ nomeCustomizado || "Grupos de Opcionais" }}
      </h3>

      <b-breadcrumb v-if="breadcrumb">
        <b-breadcrumb-item to="/config"
          ><i class="fa fa-cogs"></i> Configurações</b-breadcrumb-item
        >
        <b-breadcrumb-item active
          >{{ nomeCustomizado || "Grupos de Opcionais" }}</b-breadcrumb-item
        >
      </b-breadcrumb>
    </div>

    <div class="d-flex flex-row justify-content-between align-items-center mb-2">
      <div>
        <b-form-input
          v-model="filtroNome"
          placeholder="Filtrar por nome..."
          @input="filtrar"
          class="mb-2"
        ></b-form-input>
      </div>
      <div>
        <b-button
          v-if="botaoAdicionar"
          variant="primary"
          @click="adicionar"
          class="mb-2 mr-2"
        >
          <i class="fas fa-plus"></i> Adicionar
        </b-button>
      </div>
    </div>

    <b-table
      :items="grupos"
      :fields="campos"
      responsive
      striped
      hover
      class="mb-0 mt-3"
      :busy="loading"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      
      <template #cell(acoes)="row">
        <b-button
          size="sm"
          variant="warning"
          class="mr-1"
          @click="editar(row.item)"
        >
          <i class="fas fa-edit"></i>
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          @click="excluir(row.item)"
        >
          <i class="fas fa-trash"></i>
        </b-button>
      </template>
    </b-table>

    <b-pagination
      v-if="pagination.totalPages > 1"
      v-model="pagination.page"
      :total-rows="pagination.total"
      :per-page="pagination.limit"
      align="center"
      class="my-3"
    ></b-pagination>

    <!-- Modal de Edição -->
    <b-modal
      v-model="showModal"
      :title="modalTitle"
      @ok="salvarGrupo"
      @hidden="resetForm"
    >
      <b-form>
        <b-form-group label="Nome do Grupo:">
          <b-form-input v-model="form.nome" required placeholder="Nome do grupo de opcionais"></b-form-input>
        </b-form-group>
        <b-form-group label="Descrição:">
          <b-form-textarea
            v-model="form.descricao"
            placeholder="Descrição opcional do grupo"
            rows="3"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Ordem:">
          <b-form-input
            v-model="form.ordem"
            type="number"
            placeholder="Ordem de exibição"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ProdutoOpcionalLib from "@/libs/ProdutoOpcionalLib.js";

export default {
  props: {
    nomeCustomizado: String,
    iconeCustomizado: String,
    breadcrumb: {
      type: Boolean,
      default: true,
    },
    contentClass: String,
    botaoAdicionar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      grupos: [],
      loading: false,
      filtroNome: "",
      showModal: false,
      modalTitle: "Adicionar Grupo de Opcionais",
      form: {
        nome: "",
        descricao: "",
        ordem: 0,
      },
      editingId: null,
      campos: [
        { key: "cod_opcional_grupo", label: "ID", sortable: true },
        { key: "nome", label: "Nome", sortable: true },
        { key: "descricao", label: "Descrição" },
        { key: "ordem", label: "Ordem", sortable: true },
        { key: "acoes", label: "Ações" },
      ],
      pagination: {
        limit: 10,
        page: 1,
        total: 0,
        totalPages: 0,
      },
    };
  },
  watch: {
    "pagination.page": function() {
      this.carregar();
    },
  },
  mounted() {
    this.carregar();
  },
  methods: {
    async carregar() {
      this.loading = true;
      try {
        const response = await ProdutoOpcionalLib.getGrupos("", {
          page: this.pagination.page,
          limit: this.pagination.limit,
          filter: this.filtroNome,
        });

        this.grupos = response.data;
        
        if (response.pagination) {
          this.pagination = response.pagination;
        }
      } catch (error) {
        this.$swal({
          title: "Erro!",
          text: "Erro ao carregar grupos de opcionais: " + error,
          icon: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    
    filtrar() {
      this.pagination.page = 1;
      this.carregar();
    },
    
    adicionar() {
      this.editingId = null;
      this.modalTitle = "Adicionar Grupo de Opcionais";
      this.form = {
        nome: "",
        descricao: "",
        ordem: 0,
      };
      this.showModal = true;
    },
    
    editar(item) {
      this.editingId = item.cod_opcional_grupo;
      this.modalTitle = "Editar Grupo de Opcionais";
      this.form = {
        nome: item.nome,
        descricao: item.descricao,
        ordem: item.ordem || 0,
      };
      this.showModal = true;
    },
    
    async excluir(item) {
      try {
        const result = await this.$swal({
          title: "Tem certeza?",
          text: `Deseja excluir o grupo "${item.nome}"?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir",
          cancelButtonText: "Cancelar",
        });
        
        if (result.isConfirmed) {
          await ProdutoOpcionalLib.deleteGrupo(item.cod_opcional_grupo);
          this.$swal({
            title: "Sucesso!",
            text: "Grupo excluído com sucesso!",
            icon: "success",
          });
          this.carregar();
          this.$emit("salvou");
        }
      } catch (error) {
        this.$swal({
          title: "Erro!",
          text: "Erro ao excluir grupo: " + error,
          icon: "error",
        });
      }
    },
    
    async salvarGrupo(bvModalEvent) {
      bvModalEvent.preventDefault();
      
      if (!this.form.nome) {
        this.$swal({
          title: "Atenção!",
          text: "O nome do grupo é obrigatório!",
          icon: "warning",
        });
        return;
      }
      
      try {
        if (this.editingId) {
          await ProdutoOpcionalLib.updateGrupo(this.editingId, this.form);
        } else {
          await ProdutoOpcionalLib.storeGrupo(this.form);
          this.$emit("adicionou");
        }
        
        this.$swal({
          title: "Sucesso!",
          text: "Grupo salvo com sucesso!",
          icon: "success",
        });
        
        this.showModal = false;
        this.carregar();
        this.$emit("salvou");
      } catch (error) {
        this.$swal({
          title: "Erro!",
          text: "Erro ao salvar grupo: " + error,
          icon: "error",
        });
      }
    },
    
    resetForm() {
      this.form = {
        nome: "",
        descricao: "",
        ordem: 0,
      };
      this.editingId = null;
    },
  },
};
</script> 