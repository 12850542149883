<template>
  <div>
    <h5 class="mb-3">
      <span class="text-primary">{{ opcional_nome }}</span> - Gerenciar Itens
    </h5>
    
    <b-alert
      :show="mensagem.texto !== null"
      :variant="mensagem.tipo"
      dismissible
      @dismissed="mensagem.texto = null"
    >
      {{ mensagem.texto }}
    </b-alert>
    
    <div class="mb-4">
      <b-row>
        <b-col md="5">
          <div class="form-group">
            <label>Tipo de Item:</label>
            <b-form-select
              v-model="novoItem.tipo"
              :options="[
                { value: 'produto', text: 'Produto' },
                { value: 'ingrediente', text: 'Ingrediente' }
              ]"
              @change="onChangeTipoItem"
            ></b-form-select>
          </div>
        </b-col>
        <b-col md="5">
          <div class="form-group">
            <label>
              {{ novoItem.tipo === 'produto' ? 'Produto:' : 'Ingrediente:' }}
            </label>
            <template v-if="habilitarEntradaManual && novoItem.tipo === 'produto'">
              <b-form-input
                v-model="novoItem.nome_item"
                placeholder="Digite o nome do produto manualmente"
              ></b-form-input>
              <small class="text-muted">Entrada manual habilitada devido a erro no carregamento</small>
            </template>
            <b-form-select
              v-else
              v-model="novoItem.item_id"
              :options="itemOptions"
              :disabled="loadingItemOptions"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>
                  Selecione {{ novoItem.tipo === 'produto' ? 'um produto' : 'um ingrediente' }}
                </b-form-select-option>
              </template>
            </b-form-select>
            <div v-if="loadingItemOptions" class="text-center mt-1">
              <b-spinner small></b-spinner> Carregando...
            </div>
          </div>
        </b-col>
        <b-col md="2">
          <div class="form-group">
            <label>Valor Adicional:</label>
            <b-form-input
              v-model="novoItem.valor_adicional"
              type="text"
              placeholder="R$ 0,00"
              @input="formatarValor"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col md="6">
          <div class="form-group">
            <label>Descrição (opcional):</label>
            <b-form-input
              v-model="novoItem.descricao"
              placeholder="Descrição adicional do item"
            ></b-form-input>
          </div>
        </b-col>
        <b-col md="3">
          <div class="form-group">
            <label>Ordem:</label>
            <b-form-input
              v-model="novoItem.ordem"
              type="number"
              min="0"
              placeholder="Ordem de exibição"
            ></b-form-input>
          </div>
        </b-col>
        <b-col md="3" class="d-flex align-items-end">
          <b-button
            variant="primary"
            class="mb-3 w-100"
            @click="adicionarItem"
            :disabled="(!habilitarEntradaManual && !novoItem.item_id) || 
                      (habilitarEntradaManual && novoItem.tipo === 'produto' && !novoItem.nome_item)"
          >
            <i class="fas fa-plus"></i> Adicionar Item
          </b-button>
        </b-col>
      </b-row>
    </div>
    
    <b-card class="mb-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h6 class="mb-0">Itens Cadastrados</h6>
        <div>
          <b-form-input
            v-model="filtroItens"
            placeholder="Filtrar itens..."
            class="d-inline-block"
            style="width: 200px"
          ></b-form-input>
        </div>
      </div>
      
      <b-table
        :items="itensFiltrados"
        :fields="campos"
        responsive
        striped
        hover
        class="mb-0"
        :busy="loading"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>
        
        <template #cell(tipo)="row">
          <span class="badge" :class="row.item.tipo === 'produto' ? 'badge-info' : 'badge-warning'">
            {{ row.item.tipo === 'produto' ? 'Produto' : 'Ingrediente' }}
          </span>
        </template>
        
        <template #cell(valor_adicional)="row">
          {{ formatarMoeda(row.item.valor_adicional) }}
        </template>
        
        <template #cell(acoes)="row">
          <b-button
            size="sm"
            variant="danger"
            @click="excluirItem(row.item)"
            title="Excluir Item"
          >
            <i class="fas fa-trash"></i>
          </b-button>
        </template>
      </b-table>
      
      <div v-if="!itens.length && !loading" class="text-center p-3 text-muted">
        <i class="fas fa-info-circle mr-2"></i> Não há itens cadastrados para este opcional
      </div>
      
      <b-pagination
        v-if="pagination.totalPages > 1"
        v-model="pagination.page"
        :total-rows="pagination.total"
        :per-page="pagination.limit"
        align="center"
        class="my-3"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import ProdutoOpcionalLib from "@/libs/ProdutoOpcionalLib.js";
import ProdutoLib from "@/libs/ProdutoLib.js";
import IngredientesLib from "@/libs/IngredientesLib.js";

export default {
  props: {
    opcional_id: {
      type: [Number, String],
      required: true
    },
    opcional_nome: {
      type: String,
      default: "Opcional"
    }
  },
  data() {
    return {
      loading: false,
      loadingItemOptions: false,
      itens: [],
      produtos: [],
      ingredientes: [],
      filtroItens: "",
      habilitarEntradaManual: false,
      mensagem: {
        texto: null,
        tipo: "success"
      },
      novoItem: {
        tipo: "produto",
        item_id: null,
        nome_item: "",
        descricao: "",
        valor_adicional: "0,00",
        ordem: 0
      },
      campos: [
        { key: "id", label: "ID", sortable: true },
        { key: "tipo", label: "Tipo" },
        { key: "nome_item", label: "Nome", sortable: true },
        { key: "descricao", label: "Descrição" },
        { key: "valor_adicional", label: "Valor Adicional", sortable: true },
        { key: "ordem", label: "Ordem", sortable: true },
        { key: "acoes", label: "Ações" }
      ],
      pagination: {
        limit: 10,
        page: 1,
        total: 0,
        totalPages: 0
      }
    };
  },
  computed: {
    itemOptions() {
      if (this.novoItem.tipo === "produto") {
        return this.produtos.map(produto => ({
          value: produto.cod_produto,
          text: produto.nome
        }));
      } else {
        return this.ingredientes.map(ingrediente => ({
          value: ingrediente.id,
          text: ingrediente.nome
        }));
      }
    },
    itensFiltrados() {
      if (!this.filtroItens) {
        return this.itens;
      }
      
      const filtro = this.filtroItens.toLowerCase();
      return this.itens.filter(item => 
        item.nome_item.toLowerCase().includes(filtro) ||
        (item.descricao && item.descricao.toLowerCase().includes(filtro))
      );
    }
  },
  watch: {
    opcional_id: {
      handler() {
        this.carregarItens();
      },
      immediate: true
    },
    "pagination.page": function() {
      this.carregarItens();
    }
  },
  mounted() {
    this.carregarProdutos();
    this.carregarIngredientes();
  },
  methods: {
    async carregarProdutos() {
      this.loadingItemOptions = true;
      try {
        const result = await ProdutoLib.get("", { limit: 1000 });
        console.log(111,result);
        // Verificar a estrutura dos dados recebidos
        const primeiroItem = result.data && result.data.length > 0 ? result.data[0] : null;
        
        // Se estiver recebendo ingredientes em vez de produtos (verificando pela presença de cod_ingrediente)
        if (primeiroItem && 'cod_ingrediente' in primeiroItem) {
          // Adaptar o formato para o esperado pelo componente
          this.produtos = result.data.map(item => ({
            cod_produto: item.cod_ingrediente, // Usar cod_ingrediente como cod_produto
            nome: item.ingrediente,           // Usar ingrediente como nome
            preco_venda: item.preco_venda,
            tipo: 'ingrediente'               // Marcar como ingrediente para saber a origem
          }));
          
          this.mostrarMensagem("Aviso: O sistema está carregando ingredientes como produtos. " +
            "Isto pode indicar um problema de configuração no backend.", "warning", 8000);
        } else {
          // Formato normal de produtos
          this.produtos = result.data || [];
        }
      } catch (error) {
        // Mensagem específica para o erro de SQL
        if (error.toString().includes("sql_mode=only_full_group_by")) {
          this.mostrarMensagem("Erro na consulta SQL do servidor ao carregar produtos. Este é um problema no backend que precisa ser corrigido pelo administrador do sistema.", "danger", 10000);
        } else {
          this.mostrarMensagem("Erro ao carregar produtos: " + error, "danger");
        }
        
        // Define uma lista vazia para continuar
        this.produtos = [];
        
        // Habilita a entrada manual como alternativa
        this.habilitarEntradaManual = true;
      } finally {
        this.loadingItemOptions = false;
      }
    },
    
    async carregarIngredientes() {
      this.loadingItemOptions = true;
      try {
        const result = await IngredientesLib.get("", { limit: 1000 });
        this.ingredientes = result.data || [];
      } catch (error) {
        this.mostrarMensagem("Erro ao carregar ingredientes: " + error, "danger");
      } finally {
        this.loadingItemOptions = false;
      }
    },
    
    async carregarItens() {
      this.loading = true;
      try {
        const response = await ProdutoOpcionalLib.getItens("", this.opcional_id, {
          page: this.pagination.page,
          limit: this.pagination.limit
        });
        
        this.itens = response.data || [];
        
        if (response.pagination) {
          this.pagination = response.pagination;
        }
      } catch (error) {
        this.mostrarMensagem("Erro ao carregar itens: " + error, "danger");
      } finally {
        this.loading = false;
      }
    },
    
    onChangeTipoItem() {
      this.novoItem.item_id = null;
    },
    
    formatarValor(valor) {
      // Remove tudo que não é número
      let soNumeros = valor.replace(/\D/g, "");
      
      // Converte para número e formata
      const valorNumerico = parseFloat(soNumeros) / 100;
      this.novoItem.valor_adicional = valorNumerico.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    
    formatarMoeda(valor) {
      if (typeof valor === "string") {
        valor = parseFloat(valor.replace(",", "."));
      }
      
      return `R$ ${valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
    },
    
    converterValorParaFloat(valor) {
      if (typeof valor === "string") {
        return parseFloat(valor.replace(".", "").replace(",", "."));
      }
      return valor;
    },
    
    mostrarMensagem(texto, tipo = "success", tempo = 5000) {
      this.mensagem = { texto, tipo };
      
      // Auto-dismissible after specified time
      setTimeout(() => {
        if (this.mensagem.texto === texto) {
          this.mensagem.texto = null;
        }
      }, tempo);
    },
    
    async adicionarItem() {
      if ((!this.novoItem.item_id && !this.habilitarEntradaManual) || 
          (this.habilitarEntradaManual && this.novoItem.tipo === 'produto' && !this.novoItem.nome_item)) {
        this.mostrarMensagem("Selecione ou digite um item para adicionar", "warning");
        return;
      }
      
      try {
        // Preparar os dados para envio
        const itemData = {
          opcional_id: this.opcional_id,
          tipo: this.novoItem.tipo,
          item_id: this.novoItem.item_id || 0, // Usar 0 para entrada manual
          descricao: this.novoItem.descricao,
          valor_adicional: this.converterValorParaFloat(this.novoItem.valor_adicional),
          ordem: this.novoItem.ordem || 0
        };
        
        // Adicionar o nome do item para facilitar a exibição
        if (this.novoItem.tipo === 'produto') {
          if (this.habilitarEntradaManual) {
            itemData.nome_item = this.novoItem.nome_item;
          } else {
            const produto = this.produtos.find(p => p.cod_produto == this.novoItem.item_id);
            itemData.nome_item = produto ? produto.nome : "";
            
            // Se o produto selecionado for na verdade um ingrediente (conforme detectado em carregarProdutos)
            if (produto && produto.tipo === 'ingrediente') {
              itemData.tipo = 'ingrediente';  // Alterar o tipo para ingrediente
            }
          }
        } else {
          const ingrediente = this.ingredientes.find(i => i.id == this.novoItem.item_id);
          itemData.nome_item = ingrediente ? ingrediente.nome : "";
        }
        
        await ProdutoOpcionalLib.storeItem(itemData);
        
        this.mostrarMensagem("Item adicionado com sucesso!", "success");
        
        // Resetar o formulário
        this.novoItem = {
          tipo: "produto",
          item_id: null,
          nome_item: "",
          descricao: "",
          valor_adicional: "0,00",
          ordem: 0
        };
        
        // Recarregar a lista de itens
        this.carregarItens();
      } catch (error) {
        this.mostrarMensagem("Erro ao adicionar item: " + error, "danger");
      }
    },
    
    async excluirItem(item) {
      try {
        const result = await this.$swal({
          title: "Tem certeza?",
          text: `Deseja excluir o item "${item.nome_item}"?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, excluir",
          cancelButtonText: "Cancelar"
        });
        
        if (result.isConfirmed) {
          await ProdutoOpcionalLib.deleteItem(item.id);
          this.mostrarMensagem("Item excluído com sucesso!", "success");
          this.carregarItens();
        }
      } catch (error) {
        this.mostrarMensagem("Erro ao excluir item: " + error, "danger");
      }
    }
  }
};
</script>

<style scoped>
.badge {
  font-size: 0.9em;
  padding: 5px 8px;
}
</style> 