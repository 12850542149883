<template>
  <div>
    <b-form @submit.prevent="salvar">
      <b-row>
        <b-col md="8">
          <b-form-group label="Nome do Opcional:">
            <b-form-input
              v-model="form.nome"
              required
              placeholder="Nome do opcional"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Grupo de Opcionais:"> 
            <b-form-select
              v-model="form.cod_opcional_grupo"
              :options="gruposOptions"
              required
              :state="!!form.cod_opcional_grupo"
            ></b-form-select>
            <b-form-invalid-feedback v-if="!form.cod_opcional_grupo">
              O grupo de opcionais é obrigatório
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Descrição:">
        <b-form-textarea
          v-model="form.descricao"
          placeholder="Descrição do opcional"
          rows="2"
        ></b-form-textarea>
      </b-form-group>

      <b-row>
        <b-col md="4">
          <b-form-group label="Tipo de Seleção:">
            <b-form-radio-group
              v-model="form.selecao_multipla"
              :options="[
                { text: 'Seleção Única', value: false },
                { text: 'Múltipla Seleção', value: true }
              ]"
              buttons
              button-variant="outline-primary"
              size="sm"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="É obrigatório?">
            <b-form-radio-group
              v-model="form.obrigatorio"
              :options="[
                { text: 'Não', value: false },
                { text: 'Sim', value: true }
              ]"
              buttons
              button-variant="outline-primary"
              size="sm"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col md="4">
          <b-form-group label="Quantidade Mínima de Opções:">
            <b-form-input
              v-model="form.min_opcoes"
              type="number"
              min="0"
              required
            ></b-form-input>
            <small class="text-muted">Mínimo de opções que devem ser selecionadas</small>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Quantidade Máxima de Opções:">
            <b-form-input
              v-model="form.max_opcoes"
              type="number"
              min="1"
              :disabled="!form.selecao_multipla"
              required
            ></b-form-input>
            <small class="text-muted">
              <span v-if="form.selecao_multipla">Máximo de opções que podem ser selecionadas</span>
              <span v-else>Fixado em 1 para seleção única</span>
            </small>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Ordem:">
            <b-form-input
              v-model="form.ordem"
              type="number"
              min="0"
              placeholder="Ordem de exibição"
              required
            ></b-form-input>
            <small class="text-muted">Ordem de exibição deste opcional</small>
          </b-form-group>
        </b-col>
      </b-row>

      <div v-if="erroValidacao" class="alert alert-danger mt-3">
        {{ erroValidacao }}
      </div>
    </b-form>
  </div>
</template>

<script>
import ProdutoOpcionalLib from "@/libs/ProdutoOpcionalLib.js";

export default {
  props: {
    opcional_edit: {
      type: Object,
      default: () => ({})
    },
    grupoInicial: {
      type: [Number, String, null],
      default: null
    }
  },
  data() {
    return {
      form: {
        nome: "",
        descricao: "",
        cod_opcional_grupo: null,
        selecao_multipla: false,
        min_opcoes: 0,
        max_opcoes: 1,
        obrigatorio: false,
        ordem: 0
      },
      grupos: [],
      erroValidacao: ""
    };
  },
  computed: {
    gruposOptions() {
      console.log("grupos", this.grupos)
      const options = this.grupos.map(grupo => ({
        value: grupo.cod_opcional_grupo,
        text: grupo.nome
      }));
      
      return [
        { value: null, text: "Selecione um grupo", disabled: true },
        ...options
      ];
    },
    isEditando() {
      return this.opcional_edit && this.opcional_edit.cod_opcional;
    }
  },
  watch: {
    opcional_edit: {
      handler(newValue) {
        if (newValue && Object.keys(newValue).length > 0) {
          this.form = { ...newValue };
        }
      },
      immediate: true,
      deep: true
    },
    'form.selecao_multipla': function(val) {
      if (!val) {
        this.form.max_opcoes = 1;
      }
    }
  },
  async mounted() {
    await this.carregarGrupos();
    
    if (this.grupoInicial && !this.form.cod_opcional_grupo) {
      this.form.cod_opcional_grupo = this.grupoInicial;
    }
    
    if (this.opcional_edit && this.opcional_edit.cod_opcional) {
      this.form = { ...this.opcional_edit };
    }
  },
  methods: {
    async carregarGrupos() {
      try {
        const response = await ProdutoOpcionalLib.getGrupos();
        this.grupos = response.data || [];
      } catch (error) {
        this.$swal({
          title: "Erro!",
          text: "Erro ao carregar grupos: " + error,
          icon: "error"
        });
      }
    },
    
    validarFormulario() {
      this.erroValidacao = "";
      
      if (!this.form.nome) {
        this.erroValidacao = "O nome do opcional é obrigatório";
        return false;
      }
      
      if (!this.form.cod_opcional_grupo) {
        this.erroValidacao = "Selecione um grupo para o opcional";
        return false;
      }
      
      if (this.form.min_opcoes < 0) {
        this.erroValidacao = "A quantidade mínima de opções não pode ser negativa";
        return false;
      }
      
      if (this.form.max_opcoes < 1) {
        this.erroValidacao = "A quantidade máxima de opções deve ser pelo menos 1";
        return false;
      }
      
      if (this.form.min_opcoes > this.form.max_opcoes) {
        this.erroValidacao = "A quantidade mínima não pode ser maior que a máxima";
        return false;
      }
      
      if (this.form.obrigatorio && this.form.min_opcoes < 1) {
        this.erroValidacao = "Para opcionais obrigatórios, a quantidade mínima deve ser pelo menos 1";
        return false;
      }
      
      return true;
    },
    
    async salvar() {
      if (!this.validarFormulario()) {
        return;
      }
      
      try {
        const dadosParaEnviar = { ...this.form };
        
        dadosParaEnviar.min_opcoes = Number(dadosParaEnviar.min_opcoes);
        dadosParaEnviar.max_opcoes = Number(dadosParaEnviar.max_opcoes);
        dadosParaEnviar.ordem = Number(dadosParaEnviar.ordem || 0);
        
        dadosParaEnviar.selecao_multipla = !!dadosParaEnviar.selecao_multipla;
        dadosParaEnviar.obrigatorio = !!dadosParaEnviar.obrigatorio;
        
        if (!dadosParaEnviar.cod_opcional_grupo) {
          this.erroValidacao = "O grupo de opcionais é obrigatório";
          return;
        }
        
        if (this.isEditando) {
          await ProdutoOpcionalLib.updateOpcional(this.opcional_edit.cod_opcional, dadosParaEnviar);
        } else {
          await ProdutoOpcionalLib.storeOpcional(dadosParaEnviar);
        }
        
        this.$swal({
          title: "Sucesso!",
          text: "Opcional salvo com sucesso!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false
        });
        
        this.$emit("salvou");
        this.$bvModal.hide("modal-opcionais");
      } catch (error) {
        this.$swal({
          title: "Erro!",
          text: "Erro ao salvar opcional: " + error,
          icon: "error"
        });
      }
    }
  }
};
</script>

<style scoped>
.btn-group-sm > .btn {
  min-width: 80px;
}
</style> 