var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.salvar.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Nome do Opcional:"}},[_c('b-form-input',{attrs:{"required":"","placeholder":"Nome do opcional"},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Grupo de Opcionais:"}},[_c('b-form-select',{attrs:{"options":_vm.gruposOptions,"required":"","state":!!_vm.form.cod_opcional_grupo},model:{value:(_vm.form.cod_opcional_grupo),callback:function ($$v) {_vm.$set(_vm.form, "cod_opcional_grupo", $$v)},expression:"form.cod_opcional_grupo"}}),(!_vm.form.cod_opcional_grupo)?_c('b-form-invalid-feedback',[_vm._v(" O grupo de opcionais é obrigatório ")]):_vm._e()],1)],1)],1),_c('b-form-group',{attrs:{"label":"Descrição:"}},[_c('b-form-textarea',{attrs:{"placeholder":"Descrição do opcional","rows":"2"},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}})],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo de Seleção:"}},[_c('b-form-radio-group',{attrs:{"options":[
              { text: 'Seleção Única', value: false },
              { text: 'Múltipla Seleção', value: true }
            ],"buttons":"","button-variant":"outline-primary","size":"sm"},model:{value:(_vm.form.selecao_multipla),callback:function ($$v) {_vm.$set(_vm.form, "selecao_multipla", $$v)},expression:"form.selecao_multipla"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"É obrigatório?"}},[_c('b-form-radio-group',{attrs:{"options":[
              { text: 'Não', value: false },
              { text: 'Sim', value: true }
            ],"buttons":"","button-variant":"outline-primary","size":"sm"},model:{value:(_vm.form.obrigatorio),callback:function ($$v) {_vm.$set(_vm.form, "obrigatorio", $$v)},expression:"form.obrigatorio"}})],1)],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Quantidade Mínima de Opções:"}},[_c('b-form-input',{attrs:{"type":"number","min":"0","required":""},model:{value:(_vm.form.min_opcoes),callback:function ($$v) {_vm.$set(_vm.form, "min_opcoes", $$v)},expression:"form.min_opcoes"}}),_c('small',{staticClass:"text-muted"},[_vm._v("Mínimo de opções que devem ser selecionadas")])],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Quantidade Máxima de Opções:"}},[_c('b-form-input',{attrs:{"type":"number","min":"1","disabled":!_vm.form.selecao_multipla,"required":""},model:{value:(_vm.form.max_opcoes),callback:function ($$v) {_vm.$set(_vm.form, "max_opcoes", $$v)},expression:"form.max_opcoes"}}),_c('small',{staticClass:"text-muted"},[(_vm.form.selecao_multipla)?_c('span',[_vm._v("Máximo de opções que podem ser selecionadas")]):_c('span',[_vm._v("Fixado em 1 para seleção única")])])],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Ordem:"}},[_c('b-form-input',{attrs:{"type":"number","min":"0","placeholder":"Ordem de exibição","required":""},model:{value:(_vm.form.ordem),callback:function ($$v) {_vm.$set(_vm.form, "ordem", $$v)},expression:"form.ordem"}}),_c('small',{staticClass:"text-muted"},[_vm._v("Ordem de exibição deste opcional")])],1)],1)],1),(_vm.erroValidacao)?_c('div',{staticClass:"alert alert-danger mt-3"},[_vm._v(" "+_vm._s(_vm.erroValidacao)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }